
const FirebaseConfig = {
  apiKey: "AIzaSyB5j2xVGOc-3aaHWQ2OY5c8XhPCkUqTURc",
  authDomain: "docatorapp.firebaseapp.com",
  databaseURL: "https://docatorapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "docatorapp",
  storageBucket: "docatorapp.appspot.com",
  messagingSenderId: "1096539562070",
  appId: "1:1096539562070:web:c9d996d0e6ba3d8a5e1bf7",
  measurementId: "G-6F6DKD7YCJ"
};

export default FirebaseConfig;

